<template>
  <div>
    <div v-if="!article">
      <p
        v-for="center in project.centers"
        :key="center.slug"
        class="text-sm font-bold text-gray hover:underline font-sans"
      >
        <nuxt-link :to="`${FORM_VISIT_OUR_EXPERIMENTAL_CENTERS_BASE_URL}/${center.slug}`">
          {{ center.name }}
        </nuxt-link>
      </p>
    </div>
    <div class="flex mt-2.5">
      <icon v-if="icon" class="text-6xl text-green-dark" name="pt-ADNAGRO" />
      <nuxt-link
        v-else
        class="w-full max-w-[7rem]"
        :to="
          article
            ? `/${project?.category?.section}/${project.slug}`
            : `${PROJECTS_BASE_URL}/${project.slug}`
        "
      >
        <NuxtImg
          v-if="project.main_image.url"
          :key="project.main_image.url"
          class="image"
          :src="project.main_image.url"
          :alt="project.main_image.alternativeText"
          :title="project.meta_title"
          loading="lazy"
        />
        <NuxtImg
          v-if="!project.main_image?.url && project.media?.url"
          :key="project.slug"
          class="image"
          :src="project.media.url"
          :alt="project.media.alternativeText"
          :title="project.meta_title"
          loading="lazy"
        />
      </nuxt-link>
      <div :class="['w-full', article || icon ? 'ml-3' : 'ml-6']">
        <h3
          :class="article ? 'pt-article-title' : 'pt-project-title'"
          class="text-base font-ubuntu"
        >
          <nuxt-link
            v-if="project.blog"
            :to="
              article
                ? `${COMMUNITY_BASE_URL}/${project?.blog?.slug}/${project.slug}`
                : `${PROJECTS_BASE_URL}/${project.slug}`
            "
          >
            {{ project.title }}
          </nuxt-link>
          <nuxt-link
            v-if="!project.blog"
            :to="
              article
                ? `/${project?.category?.section}/${project.slug}`
                : `${PROJECTS_BASE_URL}/${project.slug}`
            "
          >
            {{ project.title }}
          </nuxt-link>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { FORM_VISIT_OUR_EXPERIMENTAL_CENTERS_BASE_URL, PROJECTS_BASE_URL } from '~/utils/enums';
export default {
  name: 'ProjectCard',
  props: {
    project: {
      type: Object,
      default: () => ({})
    },
    article: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      FORM_VISIT_OUR_EXPERIMENTAL_CENTERS_BASE_URL,
      PROJECTS_BASE_URL
    };
  }
};
</script>

<style scoped src="./style.scss" lang="scss" />
